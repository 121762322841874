import { defineStore } from 'pinia'
import { Privilege } from '@/services/privileges'
import { apiURL } from '@/main'

import { showError } from '@/services/axios'
import axios from 'axios'
import { emitter } from '@/main'
import { authService } from '@/services/auth.service'

export const usePrivilegesStore = defineStore('privilegesStore', {
  persist: true,
  state: () => ({
    privileges: [] as Privilege[],
    legacyApps: [] as string[]
  }),
  actions: {
    setPrivileges(privileges: Privilege[]) {
      this.privileges = privileges
      emitter.emit('privileges_set')
    },
    setLegacyApps(legacyApps: string[]) {
      this.legacyApps = legacyApps
    },
    async fetchPrivileges() {
      /*  do not fetch
       *  => when known
       *  => when the login process is not done(oidc bearer installed on axios)
       */
      if (this.privileges.length == 0) {
        try {
          await authService.getUser() //startup wait for the user
          const result = await axios.get(apiURL + '/privileges')
          this.privileges = result.data
        } catch (e) {
          showError(e)
        }
      }
      emitter.emit('privileges_fetched')
    }
  }
})
