/*

http://localhost:8080/de/translations?term=textDe:Milch&page=1&size=50&sort=id,desc&sort=messageKey,asc
https://prod.dbmilch.ch/de/masterdata?term=&page=0&size=50&sort=localUnitStatus,asc&sort=farmerStatus,asc&sort=legalEntityName1,asc
*/
export type SortItem = { key: string; order?: boolean | 'asc' | 'desc' }

export interface Group<T = any> {
  type: 'group'
  depth: number
  id: string
  key: string
  value: any
  items: readonly (T | Group<T>)[]
}

export type VuetifyDTOptionsType = {
  page: number
  itemsPerPage: number
  sortBy: SortItem[]
  groupBy: any[]
  search: string | undefined
}

export class VuetifyDTOptions implements VuetifyDTOptionsType {
  page = 0
  itemsPerPage = 50
  sortBy = []
  groupBy = []
  search = undefined
}

export type DefaultVuetifySortOptions = {
  sortBy: SortItem[]
}

export class JavaPageOptionsType {
  page: string | undefined
  size: string | undefined
  sort: string | string[] | undefined
}

export class JavaPageOptions implements JavaPageOptionsType {
  // its the URL GET Param as STRING
  page = '0'
  size = '50'
  sort = undefined // 'date_from,asc' |  ["statusSortOrder", "asc","validUntil", "desc"]
  constructor() {
    return this
  }
}

class BackendService {
  toJavaPage(options: VuetifyDTOptionsType): JavaPageOptionsType {
    const res: JavaPageOptionsType = new JavaPageOptions()

    if (options.page) {
      // -- we have to reduce the page by 1 because the spring backend starts with page 0
      // and the vuetify data table component starts with page 1
      const reducedPage = options.page - 1
      res.page = reducedPage.toString()
    }
    if (options.itemsPerPage) {
      res.size = options.itemsPerPage.toString()
    }

    if (options.sortBy && options.sortBy.length) {
      const sortArray: string[] = []
      const elementString = ''

      options.sortBy.map((item) => {
        sortArray.push(`${item.key},${item.order === true || item.order === 'desc' ? 'desc' : 'asc'}`)
      })
      res.sort = sortArray
    }
    return res
  }

  // for url and javastyle sorting
  // dt says [{key: 'textFr', order: 'asc'}]

  getJavaSort(sortBy: SortItem[]): string[] {
    const sortArray: string[] = []
    if (sortBy && sortBy.length) {
      sortBy.map((item) => {
        sortArray.push(`${item.key},${item.order === true || item.order === 'desc' ? 'desc' : 'asc'}`)
      })
    }
    return sortArray
  }

  // pass url sort values back to datatable
  getVuetifyDTSort(sort: string | string[] | undefined): SortItem[] {
    const res: VuetifyDTOptionsType = new VuetifyDTOptions()
    const type = typeof sort

    //Array types to object array is an object or string
    if (typeof sort == 'string') {
      res.sortBy.push({ key: sort.split(',')[0], order: sort.split(',')[1] == 'desc' ? 'desc' : 'asc' } as SortItem)
    } else if (typeof sort == 'object') {
      sort.forEach((element) => {
        res.sortBy.push({ key: element.split(',')[0], order: element.split(',')[1] == 'desc' ? 'desc' : 'asc' } as SortItem)
      })
    }

    return res.sortBy
  }

  toVuetifyDTOptions(options: JavaPageOptionsType, defaultVuetifySortOptions: DefaultVuetifySortOptions | undefined): VuetifyDTOptionsType {
    const res: VuetifyDTOptionsType = new VuetifyDTOptions()

    if (typeof options.sort !== 'undefined') {
      // array is an object or string
      if (typeof options.sort == 'string') {
        res.sortBy.push({
          key: options.sort.split(',')[0],
          order: options.sort.split(',')[1] == 'desc' ? 'desc' : 'asc'
        })
      } else {
        options.sort.forEach((element) => {
          res.sortBy.push({ key: element.split(',')[0], order: element.split(',')[1] == 'desc' ? 'desc' : 'asc' })
        })
      }
    } else if (!options.size) {
      // if any urparams are set do not overwrite the url
      //its undefined
      // allows default sorting and url links to a sorted page
      if (defaultVuetifySortOptions !== undefined) {
        res.sortBy = defaultVuetifySortOptions.sortBy
      }
    }

    if (options.page) {
      res.page = parseInt(options.page)
      res.page += 1
    }
    if (options.size) {
      res.itemsPerPage = parseInt(options.size)
    }

    return res
  }
}

/*

working example 0

fields: <DTSHeader[]>[
        { key: 'scheduled', title: this.$t('subsidyInvoiceRuns_tableHeader_scheduled'), sortable: true, align: 'start' },
        { key: 'subsidyTypeDe', title: this.$t('subsidyInvoiceRuns_tableHeader_subsidyType'), sortable: true, align: 'start' },
        { key: 'active', title: this.$t('subsidyInvoiceRuns_tableHeader_active'), sortable: false, align: 'start' }
      ]

working example 1
 fields: [
        { key: 'scheduled', title: this.$t('subsidyInvoiceRuns_tableHeader_scheduled'), sortable: true, align: 'start' } as DTSHeader,
        { key: 'subsidyTypeDe', title: this.$t('subsidyInvoiceRuns_tableHeader_subsidyType'), sortable: true, align: 'start' } as DTSHeader,
        { key: 'active', title: this.$t('subsidyInvoiceRuns_tableHeader_active'), sortable: false, align: 'start' } as DTSHeader
      ]


working example 2
fields: [
        { key: 'scheduled', title: this.$t('subsidyInvoiceRuns_tableHeader_scheduled'), sortable: true, align: 'start' },
        { key: 'subsidyTypeDe', title: this.$t('subsidyInvoiceRuns_tableHeader_subsidyType'), sortable: true, align: 'start' },
        { key: 'active', title: this.$t('subsidyInvoiceRuns_tableHeader_active'), sortable: false, align: 'start' }
      ] as DTSHeaders

*/

export type DTSHeader = {
  key: string
  title?: string
  sortable?: boolean
  align?: 'start' | 'end'
}

export type DTSHeaders = DTSHeader[]

export default BackendService

export const backendService: BackendService = new BackendService()
