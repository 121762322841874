<template>
  <span v-if="privileges" class="d-print-none">
    <v-overlay v-model="forceDomainSelection" />
    <span v-if="personsError" id="personsError" class="header-bar-right margin-left center-vertically">
      <v-btn icon color="red" @click="reloadLegalentitiesAndPrivileges"><span v-html="$t('unknown_error_pls_reload')" /><v-icon>mdi-cached</v-icon> </v-btn>
    </span>
    <span v-if="legalEntityStore.loading" id="domainLoading" class="header-bar-right margin-left center-vertically"
      ><v-progress-circular indeterminate />
    </span>
    <span v-else-if="!multipleDomains || (hasDomainSwitch && !hasUserSwitch)">
      <div id="domSwitch" class="header-bar-right margin-left center-vertically">
        {{ getDomainName ? getDomainName : personsService.getSelectedPersonName() }}
        <span v-if="hasDomainSwitch && !hasUserSwitch" id="domainSwitch_reset" class="link" @click="backToOriginDomain()">
          <v-icon right>mdi-close</v-icon>
        </span>
      </div>
    </span>
    <span v-else>
      <div id="domSwitchMulti" class="header-bar-right margin-left center-vertically">
        <span v-if="forceDomainSelection">
          <v-select v-model="domainId" class="domain-margin-top over-the-overlay" :items="legalEntitiesList" item-title="title" item-value="id" :menu="true" />
        </span>
        <span v-else>
          <v-select v-model="domainId" class="domain-margin-top" :items="legalEntitiesList" item-title="title" item-value="id" single-line />
        </span>
      </div>
    </span>

    <span>
      <div v-if="hasUserSwitch" id="userSwitch" class="header-bar-right margin-left center-vertically">
        <span id="userSwitch_reset" class="link" @click="backToOriginUser()">
          {{ personsService.getSelectedApplicationUserName() }}<v-icon right>mdi-close</v-icon>
        </span>
      </div>
    </span>
  </span>
</template>
<script setup lang="ts">
import { ref, computed, onMounted, onUnmounted } from 'vue'

import { privileges } from '@/services/privileges'
import { Persons } from '@/services/persons'
import { emitter } from '@/main'

import { useLegalEntityStore } from '@/store/LegalEntityStore'
import axios from 'axios'
import { apiURL } from '@/main'

const personsService = Persons.Instance
const personsError = ref(false)
const forceDomainSelection = ref(false)

const legalEntityStore = useLegalEntityStore()

const legalEntities = computed(() => legalEntityStore.legalEntities)
const legalEntitiesList = computed(() =>
  legalEntities.value.map((item) => ({ title: `${item.name1 ? item.name1 : ''} ${item.name2 ? item.name2 : ''} `, id: item.id }))
)

const domainId = computed({
  get() {
    return forceDomainSelection.value ? null : legalEntityStore.getSelectedPersonId
  },
  async set(newValue: number) {
    if (hasUserSwitch.value) {
      await personsService.switchDomainAsSelectedUser(newValue)
    }
    await personsService.setDomain(newValue)
    if (forceDomainSelection.value) {
      forceDomainSelection.value = false
      legalEntityStore.applicationUser.lastLogin = newValue
      await updateLastLogin()
    }
  }
})

const multipleDomains = computed(() => legalEntities.value?.length > 1)
const hasDomainSwitch = computed(() => legalEntityStore.hasDomainSwitch)
const getDomainName = computed(() => legalEntityStore.getDomainName)
const hasUserSwitch = computed(() => legalEntityStore.getSelectedApplicationUserId > 0)

const updateLastLogin = async () => {
  try {
    const requestedLastLogin = await axios.patch(apiURL + '/applicationUsers/updateLastLogin')
    legalEntityStore.applicationUser.lastLogin = requestedLastLogin.data.lastLogin
    legalEntityStore.applicationUser.lastSelectedLegalEntityId = requestedLastLogin.data.lastSelectedLegalEntity.id
  } catch (e) {
    console.error(e)
  }
}

const reloadLegalentitiesAndPrivileges = async () => {
  personsError.value = false
  await personsService.loadLegalEntity()
}

const backToOriginDomain = async () => {
  //const domainId = legalEntityStore.getOriginId
  await personsService.backToOriginDomain()
}

const backToOriginUser = async () => {
  legalEntityStore.setSelectedApplicationUser(0)
  await backToOriginDomain()
}

const setForceDomainSelection = () => {
  forceDomainSelection.value = true
}

onMounted(() => {
  emitter.on('forceDomainSelection', setForceDomainSelection)
})

onUnmounted(() => {
  emitter.off('forceDomainSelection')
})
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';

.over-the-overlay {
  z-index: 2100;
}
.domain-margin-top {
  margin-top: 10px !important;
}

#domSwitch {
  color: $dbm-passiv-grey !important;
}
</style>
