/**
 * plugins/index.ts
 *
 * Automatically included in `./src/main.ts`
 */

// Plugins
import vuetify from './vuetify'

// new shiny pinia store
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

import { Store } from 'pinia'

const AllPiniaStores = new Set<Store>()

const allwaysPreserve: string[] = ['versionStore']

/* unused pinaStores are still in localStorage and will only be known by pinia when in use*/
function cleanUpPreserve(AllPiniaStores: Set<Store>) {
  const allPiniaStoresString: string[] = [...AllPiniaStores].map((item) => item.$id)
  const allLocalStorageStoresString: string[] = []
  for (const key in localStorage) {
    if (key.endsWith('Store')) {
      // its a pinia store
      allLocalStorageStoresString.push(key)
    }
  }
  const allUnknownPiniaStores: string[] = allLocalStorageStoresString.filter((item) => !allPiniaStoresString.includes(item))
  allUnknownPiniaStores.map((item) => {
    localStorage.removeItem(item)
  })
  console.log('cleanUpPreserve done, preserved, removed ', allPiniaStoresString, allUnknownPiniaStores)
}

pinia.use(({ store }) => {
  AllPiniaStores.add(store)
  store.$all = AllPiniaStores

  store.$reset_all_preserve = (except: string[]) => {
    except = [...except, ...allwaysPreserve]
    store.$all.forEach((s) => {
      if (!except.includes(s.$id)) {
        console.log('$reset_all_preserve resetting ' + s.$id)
        s.$reset()
      }
    })
    cleanUpPreserve(AllPiniaStores)
  }

  store.$reset_all = () => {
    store.$reset_all_preserve([])
  }
})

declare module 'pinia' {
  export interface PiniaCustomProperties {
    $all: typeof AllPiniaStores
    /** Reset all the stores */
    $reset_all: () => void
    $reset_all_preserve: (except: string[]) => void
    $reset_by_names: (storeNames: string[]) => void
  }
}
import { router } from '../router'

// Types
import type { App } from 'vue'
import { Globals } from '@/plugins/globals'
import { Format } from '@/plugins/format'
import { Rules } from '@/plugins/rules'
import { Privileges } from '@/plugins/privileges'

export function registerPlugins(app: App) {
  app.use(vuetify).use(router).use(pinia).use(Format).use(Globals).use(Privileges).use(Rules)
}
