import { createI18n } from 'vue-i18n'

export const i18n = createI18n({
  globalInjection: true, // this makes the "this.$t" magic
  legacy: false,
  allowComposition: true, // you need to specify that!
  locale: 'de', // set locale
  fallbackLocale: 'de'
  // messageResolver:
})
