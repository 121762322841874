<template>
  <div v-if="privileges" class="d-print-none">
    <v-container id="topnav_person" class="container-xl container keepInside">
      <div id="logo" class="header-bar-left">
        <v-img
          v-if="languageKey === 'fr' || languageKey === 'it'"
          src="../assets/images/bdlaitch_rgb.svg"
          alt="dbmilch"
          class="logo-size"
          height="48"
          width="200"
        />
        <img v-else src="../assets/images/dbmilchch_rgb.svg" alt="dbmilch" class="logo-size" height="48" width="200" />
      </div>
      <div id="profile" class="header-bar-right margin-left center-vertically">
        <v-menu offset-y>
          <template #activator="{ props }">
            <span v-bind="props" id="profileMenu" class="pointer">
              <v-icon dbmpassivgrey>mdi-dots-horizontal</v-icon>
            </span>
          </template>
          <v-list>
            <v-list-subheader v-if="isAuthenticated">
              {{ userName }}
            </v-list-subheader>
            <v-list-subheader v-else> ... </v-list-subheader>
            <v-list-item>
              <v-list-item-title class="dropdown-item custom-dropdown-item-title">
                <span v-html="$t('system_menu_user_account')" />
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="isAuthenticated"
              class="text-decoration-none"
              :to="{
                name: 'profile',
                params: { lang: lang, tab: 'user' }
              }"
            >
              <v-list-subheader id="menuProfile">
                <span v-html="$t('system_menu_profile')" />
              </v-list-subheader>
            </v-list-item>
            <v-list-item>
              <v-list-subheader v-if="isAuthenticated" id="menuLogout" class="pointer" @click.prevent="signOut()">
                <span v-html="$t('system_menu_logout')" />
              </v-list-subheader>
              <v-list-subheader v-else id="menuLogin" @click.prevent="login()">
                <span v-html="$t('system_menu_login')" />
              </v-list-subheader>
            </v-list-item>
            <v-list-item>
              <v-list-item-title class="dropdown-item custom-dropdown-item-title">
                <span v-html="$t('system_menu_service')" />
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-subheader id="menuHelpContact" class="pointer" @click.prevent="gotoExternalUrl($t('url_help_contact'))">
                <span v-html="$t('system_menu_help_contact')" />
              </v-list-subheader>
            </v-list-item>
            <v-list-item>
              <v-list-subheader id="menuInstructions" class="pointer" @click.prevent="gotoExternalUrl($t('url_instructions'))">
                <span v-html="$t('system_menu_instructions')" />
              </v-list-subheader>
            </v-list-item>
            <v-list-item>
              <!-- webpack will inject a timestamp check <html lang="en" data-build-timestamp-utc="<%= new Date().toISOString() %>"> in index.html  -->
              <v-list-subheader id="version"> Build {{ builded }} </v-list-subheader>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div id="language" class="header-bar-right margin-left mt-2">
        <LanguageSelector />
      </div>
      <div v-if="$privileges.has({ path: '/masterdata', permission: 'read' })" id="search" class="header-bar-right margin-left center-vertically">
        <a class="link-right pointer text-decoration-none" @click.prevent="search()">
          <v-icon right>mdi-magnify</v-icon>
          <span v-html="$t('search')"
        /></a>
      </div>
      <div v-if="isAuthenticated" id="logout" class="header-bar-right margin-left center-vertically">
        <a class="link-right pointer text-decoration-none" @click.prevent="signOut()" v-html="$t('logout')" />
      </div>
      <div v-else id="login" class="header-bar-right margin-left center-vertically">
        <a class="link-right pointer" @click.prevent="login()" v-html="$t('login')" />
      </div>
      <v-dialog v-model="loginBlocker" persistent width="800px">
        <v-card>
          <v-card-title>
            <span v-html="$t('system_pls_login_dialog_title')" />
          </v-card-title>
          <v-card-text>
            <span v-html="$t('system_pls_login_dialog_text')" />
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click.prevent="login()">
              <span v-html="$t('system_pls_login_dialog_action_login')" />
            </v-btn>
            <v-btn color="secondary" :to="{ name: 'home', params: { lang: lang } }">
              <span v-html="$t('system_pls_login_dialog_action_home')" />
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <DomainSelector />
    </v-container>

    <div class="navigation-borders">
      <div class="container-xl container pb-0 pt-0">
        <nav class="navbar navbar-expand-lg navbar-light">
          <router-link v-if="ready" class="menu-padding navbar-brand home-color home-size" :to="{ name: 'home', params: { lang: lang } }"> HOME </router-link>
          <v-progress-linear v-if="privileges.loading" indeterminate />
          <v-btn
            class="navbar-toggler"
            aria-controls="navbarNavLightDropdown"
            :aria-expanded="isNavbarOpen.toString()"
            aria-label="Toggle navigation"
            @click="toggleNavbar"
          >
            <v-icon dbmpassivgrey> mdi-menu </v-icon>
          </v-btn>
          <div id="navbarNavLightDropdown" :class="['collapse navbar-collapse', { show: isNavbarOpen }]">
            <ul class="navbar-nav">
              <li
                v-for="item in navTree"
                v-show="item.visibility"
                :key="item.label[languageKey]"
                :class="item.selectedPath ? 'level0 nav-item dropdown menu-font selectedPath' : 'level0 nav-item dropdown menu-font'"
              >
                <a
                  :id="'level0_' + item.label[languageKey]"
                  class="nav-link menu-padding home-color"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {{ upper(item) }}
                </a>
                <ul :id="'Flyout_' + item.label[languageKey]" class="dropdown-menu dropdown-menu-light" :aria-labelledby="'level0_' + item.label[languageKey]">
                  <div class="container-xl container pb-0 pt-0 left-margin-and-padding-less">
                    <li v-for="(child, indx) in item.children" v-show="child.visibility" :key="'child1_' + indx" class="menu-column">
                      <a
                        :id="'level1_' + child.label[languageKey]"
                        :class="child.selectedPath ? 'dropdown-item custom-dropdown-item-title selectedPath' : 'dropdown-item custom-dropdown-item-title'"
                        href="#"
                        role="button"
                        >{{ child.label[languageKey] }}</a
                      >

                      <ul class="dropdown-entry dropdown-entry-hover" :aria-labelledby="'level1_' + item.label[languageKey]">
                        <li v-for="(child2, indx2) in child.children" v-show="child2.visibility" :key="'child2_' + indx2">
                          <template v-if="!child2.hideNavigation">
                            <a v-if="child2.legacy" @click="gotoLegacyNav(child2.route)">{{ child2.label[languageKey] }}</a>
                            <router-link v-else :class="child2.selectedPath ? 'selectedPath' : ''" :to="languagePrefix + child2.route">
                              {{ child2.label[languageKey] }}
                            </router-link>
                          </template>
                        </li>
                      </ul>
                    </li>
                  </div>
                </ul>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

import { navTree, NavTreeElement } from '@/router/navTree'
import LanguageSelector from '@/components/languageSelector.vue'
import { privileges } from '@/services/privileges'
import { Persons } from '@/services/persons'
import { emitter } from '@/main'

import { authService } from '@/services/auth.service'

import { useLegalEntityStore } from '@/store/LegalEntityStore'
import { format } from 'date-fns'
import DomainSelector from '@/views/DomainSelector.vue'
import { usePrivilegesStore } from '@/store/privileges'

export default defineComponent({
  name: 'Navigation',
  components: {
    LanguageSelector: LanguageSelector,
    DomainSelector
  },
  data() {
    return {
      privileges: privileges,
      navTree: navTree as NavTreeElement[],
      routeInfo: { path: 'nope' },
      selectedMegaMenu: '',
      personsService: Persons.Instance,
      personsError: false,
      ready: false,
      isNavbarOpen: false
    }
  },
  computed: {
    lang() {
      return this.$i18n.locale
    },
    isAuthenticated() {
      return typeof authService == 'object'
    },
    builded() {
      return format(document.documentElement.dataset.buildTimestampUtc, 'dd.MM.yyyy HH:mm')
    },
    loginBlocker() {
      return !this.isAuthenticated
    },
    languageKey() {
      return this.$route.params.lang
    },
    languagePrefix() {
      return '/' + this.$route.params.lang
    },
    userName() {
      if (authService.loadedUser?.profile?.family_name) {
        return authService.loadedUser.profile.family_name
      }
      if (authService.loadedUser?.profile?.given_name) {
        return authService.loadedUser.profile.given_name
      }
      if (authService.loadedUser?.profile?.email) {
        return authService.loadedUser.profile.email
      }
      return ' - '
    }
  },
  watch: {
    $route(to) {
      this.setRouteInfo(to)
    }
  },
  mounted() {
    emitter.on('initialized', (e) => {
      console.log('got an init cmd')
      this.ready = true
    })
    emitter.on('reloadNav', this.reloadNav)
    emitter.on('privileges_set', this.reloadNav) // this habppend after a reset
    emitter.on('privileges_fetched', this.reloadNav) // this happend after a domainswitch
    //emitter.on('oidcUserLoaded')
    emitter.on('oidcUserUnloaded', this.oidcUserError)
    emitter.on('oidcSilentRenewError', this.oidcUserError)
    emitter.on('oidcSigninSilent', this.personsService.oidcSigninSilent)
    emitter.on('oidcSigninCallback', this.personsService.oidcSigninCallback)
    emitter.on('backendUnAuthorized', (e) => {
      console.log('got an backendUnAuthorized')
      this.oidcUserError()
    })
    this.reloadNav()
  },
  unmounted() {
    emitter.off('reloadNav', this.reloadNav)
    emitter.off('privileges_set', this.reloadNav)
    emitter.off('privileges_fetched', this.reloadNav)
    //emitter.off('oidcUserLoaded')
    emitter.off('oidcUserUnloaded')
    emitter.off('oidcSilentRenewError')
    emitter.off('oidcSigninSilent')
    emitter.off('oidcSigninCallback')
    emitter.off('backendUnAuthorized')
    emitter.off('routePushHome')
  },
  methods: {
    cleanup() {
      this.personsService.reset()
      //todo
      let somePiniaStore = useLegalEntityStore()
      somePiniaStore.$reset_all()
      this.reloadNav()
    },
    signOut() {
      this.cleanup()
      window.setTimeout(() => {
        authService.logout()
      }, 250)
    },
    oidcUserError() {
      console.log('oidcUserError')
      this.cleanup()
      window.setTimeout(() => {
        authService
          .login()
          .then(() => {
            console.log('oidcinterceptor igninRedirect success')
          })
          .catch(() => {
            emitter.emit('toastError', { message: this.$t('idp_unavailable') })
          })
      }, 250)
    },
    upper(item) {
      return item.label[this.languageKey ? this.languageKey : 'de'].toUpperCase()
    },
    gotoExternalUrl(url) {
      window.open(url, '_blank')
    },
    toggleNavbar() {
      this.isNavbarOpen = !this.isNavbarOpen
    },
    search(e) {
      let routeName = 'masterdata_search'
      if (privileges.has({ path: '/masterdata/find', permission: 'read' })) {
        routeName = 'masterdata_find'
      }
      this.$router
        .push({
          name: routeName,
          params: this.$route.params
        })
        .catch((e) => {
          //you need to have a catcher
        })
    },
    setRouteInfo(routeInfo) {
      this.routeInfo = routeInfo.path
      this.setFocusElements(this.navTree, this.routeInfo && this.routeInfo.length ? this.routeInfo : '#nope')
    },
    setFocusElements(arrayOfRouteElements, focusPath) {
      let anyFocus = false
      arrayOfRouteElements.forEach((element) => {
        if (focusPath.replace(/\/\d+/, '/read').search(element.route) > -1) {
          // replace /{id} with /read
          // TRUE :: ("#/de/processingFormTemplates/read").search("/processingFormTemplates")
          element.selectedPath = true
          anyFocus = true
        } else {
          if (element.children && element.children.length > 0) {
            // check the children
            element.selectedPath = this.setFocusElements(element.children, focusPath)
            anyFocus = anyFocus || element.selectedPath
          } else {
            element.selectedPath = false
          }
        }
      })
      return anyFocus
    },
    setNavigationVisibility(arrayOfRouteElements: NavTreeElement[]) {
      let somethingVisible = false
      arrayOfRouteElements.forEach((element) => {
        if (element.children && element.children.length > 0) {
          element.visibility = this.setNavigationVisibility(element.children)
        } else {
          // no children

          // do not display betaFeatures
          if (element.visibilityBeta && element.visibilityBeta === true && !this.$getBeta()) {
            element.visibility = false
            return false
          }
          // legacy link
          element.legacy = element.route.substring(0, 7) === '/legacy'
          if (element.legacy && !this.$legacyEnabled()) {
            element.visibility = false
            return false
          }
          // detect path ending with search/read
          if (element.route.substring(element.route.length - '/search'.length) === '/search') {
            const testpath = element.route.substring(0, element.route.length - '/search'.length)
            element.visibility = privileges.has({
              path: testpath,
              permission: 'search'
            })
          } else if (element.route.substring(element.route.length - '/read'.length) === '/read') {
            const testpath = element.route.substring(0, element.route.length - '/read'.length)
            element.visibility = privileges.hasOneOf([
              {
                path: testpath,
                permission: 'read'
              },
              {
                path: testpath,
                permission: 'search'
              }
            ])
          } else {
            element.visibility = privileges.has({
              path: element.route,
              permission: 'read'
            })
          }
        }
        somethingVisible = somethingVisible || element.visibility
      })
      // already returned true above if any element is true
      return somethingVisible
    },
    reloadNav() {
      console.log('got a reloadnav')
      this.setNavigationVisibility(this.navTree)
    },
    async backToOriginDomain() {
      let legalEntityStore = useLegalEntityStore()
      const domainId = legalEntityStore.getOriginId
      await this.personsService.backToOriginDomain(domainId)
    },
    async backToOriginUser() {
      let legalEntityStore = useLegalEntityStore()
      legalEntityStore.setSelectedApplicationUser(0)
      await this.backToOriginDomain()
    },

    getDomainText: (item) => ` ${item.name1 ? item.name1 : ''} ${item.name2 ? item.name2 : ''} `,
    gotoLegacyNav(route) {
      this.$gotoLegacy(route.split('/')[2], route.split('/')[3])
    },
    routePushHome() {
      this.$router.push({ name: 'home', params: { lang: this.$route.params.lang } }).catch((e) => {
        /* duplicated route */
      })
    }
  }
})

//import 'bootstrap' // js files, the navigation based on this
//  import { Navigation } from 'bootstrap.esm.min.js'

import 'bootstrap/js/dist/dropdown'
</script>
<style lang="scss" scoped>
@import 'bootstrap/dist/css/bootstrap.min.css';

.dropdown-menu {
  width: 100%;
  background: #f1f1f1;
}
</style>
