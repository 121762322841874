import { authService } from '@/services/auth.service'
import { Plugin } from 'vue'

import moment from 'moment'
import { useLegalEntityStore } from '@/store/LegalEntityStore'
import { useSettingsStore } from '@/store/SettingsStore'
import { format } from 'date-fns'
import { privileges } from '@/services/privileges'

/*
declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $getUpLang():String;
    $getIsoLangKey():String;
    $getLangKey():String;
    $getUpLangKey(): String;
    $getBeta:boolean;
    $toHeader:any;
    $defaultFooter:number[]
  }
}

export {}  // Important! See note.
*/
export const Globals: Plugin = {
  install(app: any, options: any) {
    app.config.globalProperties.$getBeta = () => {
      const settingsStore = useSettingsStore()
      return settingsStore.getBeta
    }
    app.config.globalProperties.$getDebug = () => {
      const settingsStore = useSettingsStore()
      return settingsStore.getDebug
    }
    app.config.globalProperties.$itemsPerPage = 50
    app.config.globalProperties.$itemsPerPageOptions = [10, 20, 50, 100]
    app.config.globalProperties.$moment = moment
    app.config.globalProperties.$format = format
    app.config.globalProperties.$privileges = privileges
    app.config.globalProperties.$legacyEnabled = () => {
      // Legacy-Access only with Agate-Token and without Domain-Switch
      const legalEntityStore = useLegalEntityStore()
      const settingsStore = useSettingsStore()

      return settingsStore.getOIDC === 'agate' && !(legalEntityStore.getSelectedPersonId != legalEntityStore.getOriginLegalEntityId)
    }

    app.config.globalProperties.$gotoLegacy = (app: string, nav: number) => {
      const legalEntityStore = useLegalEntityStore()
      let legacyUrl =
        location.hostname === 'prod.dbmilch.ch' || location.hostname === 'prod.bdlait.ch' ? 'https://legacy.dbmilch.ch/' : 'https://test.dbmilch.ch/'

      legacyUrl += app + '/index.cfm?action=login&domainID=' + legalEntityStore.getSelectedPersonId + '&jwt=' + authService.loadedUser?.access_token
      if (nav) legacyUrl += '&Nav=' + nav
      console.log(legacyUrl)
      window.open(legacyUrl, '_blank')
    }

    app.config.globalProperties.$goNext = (elem: any) => {
      const currentIndex = Array.from(elem.form.elements).indexOf(elem)
      const activeElems: any[] = Array.from(elem.form.elements)
        .filter((el: any, index: number) => index > currentIndex)
        .filter((el: any) => !el.disabled)
      if (activeElems.length) {
        activeElems[0].focus()
      } else {
        elem.form.elements.item(0).focus()
      }
    }
  }
}
