import { defineStore } from 'pinia'
import { LegalEntities } from '@/domain/LegalEntities'
import { personsService, ApplicationUserList } from '@/services/persons'

export const useLegalEntityStore = defineStore('legalEntityStore', {
  persist: true,
  state: () => ({
    applicationUser: null as null | ApplicationUserList,
    legalEntities: [] as LegalEntities[],
    selectedPersonId: null as number | null, // chosen LegalEntitie
    selectedPerson: null as null | any,
    selectedLegalEntityId: null as number | null,
    selectedLegalEntity: null as null | any,
    selectedApplicationUserId: null as number | null,
    selectedApplicationUserName: '',
    originLegalEntityId: null as number | null,
    domSwitchLegalEntity: null as null | any,
    loading: false,
    loaders: 0
  }),
  getters: {
    //getSelectedPerson: (state) => state.selectedPerson,
    getSelectedPersonId: (state) => state.selectedLegalEntityId,
    getSelectedLegalEntityId: (state) => state.selectedLegalEntityId,
    getSelectedApplicationUserId: (state) => state.selectedApplicationUserId,
    hasDomainSwitch: (state) => state.selectedLegalEntityId != state.originLegalEntityId,
    getOriginId: (state) => state.originLegalEntityId,
    getOriginLegalEntityId: (state) => state.originLegalEntityId,
    getDomainName: (state) => {
      let legalEntityName = ''
      if (state.selectedLegalEntityId === state.originLegalEntityId) {
        legalEntityName = state.selectedPerson?.name1
        if (state.selectedPerson?.name2) legalEntityName += ' ' + state.selectedPerson?.name2
      } else {
        legalEntityName = state.domSwitchLegalEntity.legalEntityName1
        if (state.domSwitchLegalEntity.legalEntityName2) legalEntityName += ' ' + state.domSwitchLegalEntity.legalEntityName2
      }
      return legalEntityName
    },
    getApplicationUser: (state) => state.applicationUser
  },
  actions: {
    incLoaders() {
      this.loaders++
      this.loading = this.loaders > 0
    },
    decLoaders() {
      this.loaders--
      this.loading = this.loaders > 0
    },
    setApplicationUser(applicationUser: ApplicationUserList) {
      this.applicationUser = applicationUser
    },
    setLegalEntities(le: LegalEntities[]) {
      this.legalEntities = le === undefined ? [] : le.sort((a, b) => (a.name1 > b.name1 ? 1 : b.name1 > a.name1 ? -1 : 0))
    },
    setDomain(newLegalEntityId: number | null) {
      /* only for direct connections to LegalEntity, do not use this for domain-switching */
      this.selectedLegalEntityId = newLegalEntityId
      this.selectedPersonId = this.selectedLegalEntityId
      if (!this.selectedApplicationUserId) {
        // only set originLegalEntityId when the user is not in userswitch mode
        this.originLegalEntityId = newLegalEntityId
      }
      this.selectedLegalEntity = this.legalEntities.filter((pers: LegalEntities) => pers.id == newLegalEntityId)[0]
      this.selectedPerson = this.selectedLegalEntity
    },
    setSelectedPersonId(id: number) {
      this.selectedPersonId = id
      this.applicationUser.lastSelectedLegalEntityId = id
    },
    setSelectedApplicationUser(selectedApplicationUser: ApplicationUserList) {
      // test if name is digits only, if yes, display email instead
      this.selectedApplicationUserName =
        !selectedApplicationUser.name || /^\d+$/.test(selectedApplicationUser.name) ? selectedApplicationUser.email : selectedApplicationUser.name
      this.selectedApplicationUserId = selectedApplicationUser.id
      /* only for direct connections to LegalEntity, do not use this for domain-switching */
      if (selectedApplicationUser.id) {
        // copy to domainswitch
        this.originLegalEntityId = 1
        this.selectedLegalEntityId = selectedApplicationUser.allowedLegalEntities[0].id
        this.selectedPersonId = this.selectedLegalEntityId
        this.domSwitchLegalEntity = { ...this.selectedLegalEntity }
        this.selectedLegalEntity = {}
        personsService.legalEntities = selectedApplicationUser.allowedLegalEntities
        this.legalEntities = selectedApplicationUser.allowedLegalEntities
      } else {
        //restore from domainswitch
        this.selectedLegalEntityId = 1
        this.selectedPersonId = this.selectedLegalEntityId
        this.originLegalEntityId = 1
        this.selectedLegalEntity = { ...this.domSwitchLegalEntity }
        this.domSwitchLegalEntity = {}
        this.legalEntities = []
      }
    },
    switchDomain(legalEntity: LegalEntities) {
      this.domSwitchLegalEntity = legalEntity
      this.selectedLegalEntityId = legalEntity.legalEntityId
      this.selectedPersonId = this.selectedLegalEntityId
    },
    resetDomain() {
      this.selectedLegalEntityId = this.originLegalEntityId
      this.selectedPersonId = this.selectedLegalEntityId
    },
    resetLoaders() {
      this.loaders = 0
    }
  }
})
