import { isDate } from 'util/types'

import { CustomFormatter } from '@/views/translations/CustomFormatter'
import { Plugin } from 'vue'
import { app } from '@/main'

import { i18n } from '@/i18n'

const thousandSeparator = Intl.NumberFormat('de-CH')
  .format(11111)
  .replace(/\p{Number}/gu, '')

// convert "31.12.2023" into a JS Date Object
function parseCHDate(chDate: string): Date {
  const [day, month, year] = chDate.split('.')
  return new Date(`${year}-${month}-${day}`)
}

export const $rules = {
  required: (value: any) => !!value || app.config.globalProperties.$t('rules_required'), //we can't use this for booleans because it can't tell the difference between the value false and null
  isNotNull: (value: any) => value != null || app.config.globalProperties.$t('rules_required_boolean'),
  min8: (v: any) => v.length >= 8 || app.config.globalProperties.$t('rules_minimum_8_characters'), // as example
  length7: (v: number) => `${v}`.length == 7 || app.config.globalProperties.$t('rules_exactly_7_characters'),
  isNumeric: (v: any) => {
    if (v === null) return true
    if (v === undefined) return true

    // convert v into string
    if (typeof v === 'number') {
      return true
    }
    if (typeof v === 'string') {
      v = v.trim()
      if (v === '') {
        return true
      }
      if (!v.toString().match(new RegExp('[^\\d.' + thousandSeparator + '-]'))) return true // accepts 12`445.15 as a number and negative numbers as well -12`445.15
      // new RegExp('ab+c', 'i')
    }
    return app.config.globalProperties.$t('rules_not_a_number')
  },
  belowTenMegaAbs(value: any) {
    if (value === null) return true
    if (value === undefined) return true
    // the db field is a decimal(9.2)
    value = parseInt(value.toString().replace(/['’]/g, ''))
    if (value != 'NaN') {
      if (Math.abs(value) < 10000000) return true
    }
    return app.config.globalProperties.$t('rules_below_ten_mio')
  },
  // chDateStrig = "31.12.2023"
  isMaxTwoMonthsPast: (chDateString: any): boolean | string => {
    if (chDateString === undefined || chDateString === null || chDateString === '') return true
    const [year, month] = [new Date().getFullYear(), new Date().getMonth()]
    const twoMonthAgoTheFirst = new Date(`${year}-${month}-01`).setMonth(month - 2)
    return parseCHDate(chDateString).getTime() <= twoMonthAgoTheFirst ? i18n.t('rules_date_more_than_two_months_ago').toString() : true
  },
  // form, until are IsoDateString or Null or undefined
  isUntilGreaterThanFrom: (from: any, until: any): boolean | string => {
    if (until === undefined || until == null || until.trim() == '') return true
    if (from === undefined || from == null) return i18n.t('DBM_ERROR_160').toString()
    return new Date(until).getTime() < new Date(from).getTime() ? i18n.t('DBM_ERROR_160').toString() : true
  },
  isNumericList: (v: any) => {
    if (v === null || v === '') return true
    // accepts numbers separated by commas
    if (v.toString().match(new RegExp('^[0-9]+(,[0-9]+)*,?$'))) return true
    return app.config.globalProperties.$t('rules_only_numbers_and_commas')
  },
  isSelected: (v: any) => {
    //v-select: in its undefined state the return value has type 'function' otherwise it can be object or string
    return typeof v == 'string'
  },
  isCHDate: (v: any) => {
    if (v === null || v === undefined) return true
    if (typeof v === 'string') {
      if (v === '') {
        return true
      }

      const parms = v.split(/\./)

      if (parms.length != 3) {
        return app.config.globalProperties.$t('rules_ch_date_not_a_date_year')
      }
      const yyyy = parseInt(parms[2], 10)
      if (yyyy < 1900) {
        return app.config.globalProperties.$t('rules_ch_date_not_a_date_year')
      }
      const mm = parseInt(parms[1], 10)
      if (mm < 1 || mm > 12) {
        return app.config.globalProperties.$t('rules_ch_date_not_a_date_month')
      }
      const dd = parseInt(parms[0], 10)
      if (dd < 1 || dd > 31) {
        return app.config.globalProperties.$t('rules_ch_date_not_a_date_day')
      }
      const dateCheck = new Date(yyyy, mm - 1, dd)

      return dateCheck.getDate() === dd && dateCheck.getMonth() === mm - 1 && dateCheck.getFullYear() === yyyy ? true : 'not a date'
    }
    return 'not a date'
  },
  isValidMonthRange: (from: any, to: any, range: number) => {
    const toDate = new Date(to)
    const fromDate = new Date(from)
    const formatter = new CustomFormatter()

    const monthsInBetween = toDate.getMonth() - fromDate.getMonth() + 12 * (toDate.getFullYear() - fromDate.getFullYear())
    return monthsInBetween <= range && toDate >= fromDate
      ? true
      : formatter.interpolate(app.config.globalProperties.$t('rules_month_not_a_valid_time_range').toString(), [range.toString()])
  },
  isCHMonth: (v: any) => {
    if (v === null) return true
    v = v.toString()
    if (!v.trim()) return true

    const parms = v.split(/\./)
    const yyyy = parseInt(parms[1], 10)
    if (yyyy < 1900) {
      return app.config.globalProperties.$t('rules_ch_month_not_a_date_year')
    }
    const mm = parseInt(parms[0], 10)
    if (mm < 1 || mm > 12) {
      return app.config.globalProperties.$t('rules_ch_month_not_a_date_month')
    }

    const dateCheck = new Date(yyyy, mm - 1, 1)
    return dateCheck.getDate() === 1 && dateCheck.getMonth() === mm - 1 && dateCheck.getFullYear() === yyyy
      ? true
      : app.config.globalProperties.$t('rules_ch_month_not_a_date')
  }
}

export const Rules: Plugin = {
  install(app: any, options: any) {
    app.config.globalProperties.$rules = $rules
  }
}
