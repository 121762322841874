<template>
  <v-dialog v-model="dialog" max-width="800px">
    <v-card>
      <v-card-title>
        <span v-html="$t('subsidy_invoice_document_dialog_header')" />
      </v-card-title>
      <v-card-text>
        <div>
          <span v-html="$t('subsidy_invoice_document_dialog_info')" />
          <router-link
            v-if="$privileges.has({ path: '/documents/inbox', permission: 'read' })"
            :to="{ name: 'inbox_read', query: { term: 'documentTypeGroup:DOWNLOADS', size: 50 } }"
            class="link"
          >
            <br />
            <span v-html="$t('subsidy_invoice_document_dialog_link')" />
          </router-link>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="closeDialog()">
          <span v-html="$t('close')" />
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
import { emitter } from '@/main'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'DownloadInfoDialog',
  data() {
    return {
      dialog: false
    }
  },
  mounted() {
    emitter.on('openDownloadInfoDialog', this.openDownloadInfoDialog)
  },
  methods: {
    closeDialog() {
      this.dialog = false
    },
    openDownloadInfoDialog() {
      this.dialog = true
    }
  }
})
</script>
