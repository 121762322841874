/*  usefull when convert URL 'de'  into  nameDe
 */
export function capitalize(s: string): string {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

/*
    returns the nameDe, nameFr   
*/
export function nameLocale(language: string): string {
  return 'name' + capitalize(language)
}

export function stringOrEmpty(string_to_test: string | null | undefined): string {
  return string_to_test ? string_to_test : ''
}
