<script lang="ts">
import { useToast } from 'vue-toastification'
//import { ToastOptions } from 'vue-toasted'
import { emitter } from '@/main'

import { ToastOptionsAndContent } from '/vue-toastification/dist/types/types'

const toastDefaults: ToastOptionsAndContent = {
  timeout: 8000,
  position: 'bottom-right'
}

type ToastMessage = {
  message: string
}

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Toaster',
  data() {
    return { toast: useToast() }
  },
  mounted() {
    emitter.on('toastSuccess', this.toastSuccess)
    emitter.on('toastWarning', this.toastWarning)
    emitter.on('toastError', this.toastError)
  },
  unmounted() {
    emitter.off('toastSuccess')
    emitter.off('toastWarning')
    emitter.off('toastError')
  },
  methods: {
    //https://github.com/Maronato/vue-toastification#features
    toastSuccess(msg: ToastMessage | string): void {
      this.toast.success(this.msgToString(msg), toastDefaults)
    },
    toastWarning(msg: ToastMessage | string) {
      this.toast.warning(this.msgToString(msg), toastDefaults)
    },
    toastError(msg: ToastMessage | string) {
      this.toast.error(this.msgToString(msg), toastDefaults)
    },
    msgToString(msg: ToastMessage | string): string {
      if (typeof msg?.message === 'string') {
        return msg.message
      }
      if (typeof msg == 'string') return msg
    }
  }
})
</script>
