/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles are in app.vue
//import '@mdi/font/css/materialdesignicons.css'
//import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import { VTreeview } from 'vuetify/labs/VTreeview'
import '@/styles/main.scss' //vite.config.ts + plugins/vuetify.ts + app.vue

const myCustomLightTheme = {
  dark: false,
  colors: {
    primary: '#00a1db',
    secondary: '#ffffff',
    accent: '#00A1DB',
    error: '#b71c1c'
  },
  btn: {
    borderRadius: 5
  }
}
import { createVueI18nAdapter } from 'vuetify/locale/adapters/vue-i18n'
import { useI18n } from 'vue-i18n'
import { de, fr, it } from 'vuetify/locale'
import { i18n } from '@/i18n'
// import { VBtn } from 'vuetify/vuetify'

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  components: {
    VTreeview
    // VDataTableServer, VDatePicker, VDataTableVirtual
  },
  locale: {
    //adapter: createVueI18nAdapter({ i18n, useI18n }),
    locale: 'de',
    messages: { de, fr, it }
  },
  defaults: {
    VBadge: {
      color: 'primary'
    },
    VBtn: {
      elevation: 0,
      variant: 'flat',
      style: [
        {
          textTransform: 'none',
          borderColor: myCustomLightTheme.colors.primary
        }
      ]
    },
    VContainer: { class: 'container-xl container ' },
    VDataTableServer: {
      itemsPerPageOptions: [
        { value: 10, title: '10' },
        { value: 25, title: '25' },
        { value: 50, title: '50' },
        { value: 100, title: '100' }
      ]
      // pageText: '{0}-{1} of! {2}'
    },
    VDatePicker: {
      locale: 'de',
      firstDayOfWeek: 1,
      VBtn: {
        style: {
          border: 'none'
        }
      }
    },
    VList: {
      density: 'compact'
    },
    VListItem: {
      VBtn: {}
    },
    VProgressCircular: {
      style: [
        {
          color: myCustomLightTheme.colors.primary
        }
      ]
    },
    VProgressLinear: {
      style: [
        {
          color: myCustomLightTheme.colors.primary
        }
      ]
    },
    VSelect: {
      density: 'compact'
    },
    VSwitch: {
      density: 'compact'
    },
    VTab: {
      style: {
        borderColor: 'transparent',
        textTransform: 'caps'
      }
    },
    VTabs: {
      style: {}
    },
    VTextField: {
      outlined: false,
      rounded: false,
      density: 'compact',
      VInput: {
        textFieldBorderRadius: 'none'
      }
    }
  },
  theme: {
    defaultTheme: 'myCustomLightTheme',
    themes: {
      myCustomLightTheme
    }
  }
})
